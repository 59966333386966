import React, { Component } from 'react';
import { graphql } from 'gatsby';
import PageBuilder from 'components/Craft/PageBuilder';
import Seo from 'components/Seo';
import Wrapper from 'components/Wrapper';
import Headline from 'components/Headline';

export default class Pages extends Component {
  render() {
    const { location, data } = this.props;
    const { id, title, slug, pageBuilder } = data?.craft?.entry;
    const { seoTitle, seoDescription, seoImage } = data?.craft?.entry;

    return (
      <>
        {seoImage?.length > 0 ? (
          <Seo
            url={location?.href}
            title={seoTitle || title}
            description={seoDescription}
            image={seoImage}
          />
        ) : (
          <Seo
            url={location?.href}
            title={seoTitle || title}
            description={seoDescription}
          />
        )}

        <section id={slug} key={`${id}-${slug}`}>
          <Wrapper>
            {pageBuilder.pageSubtitle && (
              <Headline element="h5">{pageBuilder.pageSubtitle}</Headline>
            )}
            <Headline element="h1">{title}</Headline>
            <PageBuilder pageBuilder={pageBuilder} />
          </Wrapper>
        </section>
      </>
    );
  }
}

export const pageQuery = graphql`
  query QueryPage($id: [Int]!) {
    craft {
      entry(id: $id) {
        id
        title
        slug
        ... on Craft_Pages {
          pageSubtitle
          pageBuilder {
            ...PageBuilderQuery
          }
          # seo fields
          seoTitle
          seoDescription
          seoImage {
            id
            socialMediaUrl: url(transform: transformSocialMedia)
          }
        }
      }
    }
  }
`;
